import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {ApiDocSidebar} from "../../components/sidebar";
import {KeyHeader, KeyNestedHeader} from "../../components/typography";
import {Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {ServerBadgeList} from "../../components/servers";

const pageTitle = "Build Apps";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <KeyHeader className="mt-0">Compiling and Linking Applications with SQLAPI++</KeyHeader>

        <p>
            To build and run a program that interfaces with a database using SQLAPI++ you need the following components
            of the library:
        </p>

        <ul>
            <li><a href="#headers">Header file(s)</a></li>
            <li><a href="#link-time-library">Link-time library</a></li>
            <li><a href="#run-time-library">Run-time library</a> (optionally)</li>
        </ul>

        <KeyNestedHeader id="headers">Header files - Compile time</KeyNestedHeader>
        <p>
            Developers need to include SQLAPI++ header files into their source files. Those with a command-line compiler
            will typically use options such as {' '}<code>{'/I%SQLAPIDIR%\\include'}</code>{' '} or
            {' '}<code>-I${`{SQLAPIDIR}`}/include</code>.
            The header files are in the <strong>include</strong> subdirectory of SQLAPI++ distributions.
        </p>

        <CodeSnippet>{`#include <SQLAPI.h>`}</CodeSnippet>

        <p>
            This is the main header of the library. Most of the time this is the only header that you need to write code
            with SQLAPI++. Classes in this header provide unified access to every supported database.
        </p>

        <Callout heading="Access to Native API">
            <p>
                Occasionally, you may need to use a very specific feature available only for given database. In that
                case
                you need to also include one of the additional headers to be able to leverage server-specific APIs.
            </p>
            <div>
                <details>
                    <summary>
                        For more information about server specific headers and APIs,
                        see {' '}<Link className="alert-link" to="/ApiDoc/servers/">Server Specific Guides</Link>.
                    </summary>
                    <div className="mt-2">
                        <ServerBadgeList linkClass="alert-link" linkType="api" badgeBorder="warning"/>
                    </div>
                </details>
            </div>
        </Callout>

        <KeyNestedHeader id="link-time-library">Static or dynamic libraries - Link time</KeyNestedHeader>

        <p>
            Developers need to link with static or dynamic libraries.
            Those with a command-line compiler will typically use options
            such as <code>/L%SQLAPIDIR%\lib sqlapi.lib</code> or <code>{`-L\${SQLAPIDIR}/lib -lsqlapi`}</code>.
        </p>

        <p>
            The libraries are in the <strong>lib</strong> subdirectory of SQLAPI++ distributions:
        </p>

        <h6>Windows</h6>
        <ul>
            <li>
                <code>sqlapi.lib</code> - for linking with release version of dynamic library (MS Visual Studio C++)
            </li>
            <li><code>sqlapid.lib</code> - for linking with debug version of dynamic library (MS Visual Studio C++)</li>
            <li>
                <code>sqlapis.lib</code> - for linking with release version of static library (MS Visual Studio C++)
            </li>
            <li><code>sqlapisd.lib</code> - for linking with debug version of static library (MS Visual Studio C++)</li>
            <li><code>sqlapib.lib</code> - for linking with release version of dynamic library (Borland C++)</li>
            <li><code>sqlapibd.lib</code> - for linking with debug version of dynamic library (Borland C++)</li>
            <li><code>sqlapibs.lib</code> - for linking with release version of static library (Borland C++)</li>
            <li><code>sqlapibsd.lib</code> - for linking with debug version of static library (Borland C++)</li>
            <li><code>libsqlapidll.a</code> - for linking with release version of dynamic library (MinGW)</li>
            <li><code>libsqlapiddll.a</code> - for linking with debug version of dynamic library (MinGW)</li>
            <li><code>libsqlapi.a</code> - for linking with release version of static library (MinGW)</li>
            <li><code>libsqlapid.a</code> - for linking with debug version of static library (MinGW)</li>
        </ul>

        <h6>Linux/Unix</h6>
        <ul>
            <li>
                <code>libsqlapi.so</code> - for linking with release version of dynamic library (GNU C and C++ project
                compiler)
            </li>
            <li>
                <code>libsqlapid.so</code> - for linking with debug version of dynamic library (GNU C and C++ project
                compiler)
            </li>
            <li>
                <code>libsqlapi.a</code> - for linking with release version of static library (GNU C and C++ project
                compiler)
            </li>
            <li>
                <code>libsqlapid.a</code> - for linking with debug version of static library (GNU C and C++ project
                compiler)
            </li>
        </ul>

        <Callout heading="Unicode Libraries">
            Unicode versions of the libraries have 'u' suffix. To compile and link the application with Unicode
            SQLAPI++ library use SA_UNICODE define.
        </Callout>

        <KeyNestedHeader id="run-time-library">Dynamic Link Libraries (DLLs) - Run time</KeyNestedHeader>

        <p>
            Users of programs built with the dynamic SQLAPI++ libraries need those same dynamic libraries to run
            the programs. The DLLs are in the <strong>bin</strong> subdirectory of SQLAPI++ distributions:
        </p>

        <h6>Windows</h6>
        <ul>
            <li><code>sqlapi.dll</code> - release version of dynamic library compiled with MS Visual Studio C++</li>
            <li><code>sqlapid.dll</code> - debug version of dynamic library compiled MS Visual Studio C++</li>
            <li><code>sqlapib.dll</code> - release library compiled with Borland C++</li>
            <li><code>sqlapibd.dll</code> - debug library compiled with Borland C++</li>
            <li><code>libsqlapi.dll</code> - release library compiled with MinGW</li>
            <li><code>libsqlapid.dll</code> - debug library compiled with MinGW</li>
        </ul>

        <h6>Linux/Unix</h6>
        <ul>
            <li><code>libsqlapi.so</code> - for programs linked with the release version of dynamic library</li>
            <li><code>libsqlapid.so</code> - for programs linked with the debug version of dynamic library</li>
        </ul>

        <Callout heading="Unicode Libraries">
            Unicode versions of shared libraries have 'u' suffix.
        </Callout>
    </Layout>
);

export default Page;
