import React from "react";

const KeyHeader = ({className = "mt-5", id, children}) => (
    <h2 id={id} className={className}>{children}</h2>
);

const KeyNestedHeader = ({className = "mt-5", id, children}) => (
    <h3 className={className}>
        <span id={id} className="header-target"/>
        {children}
    </h3>
);

export {
    KeyHeader,
    KeyNestedHeader
};
