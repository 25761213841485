import React from "react";
import {Link} from "gatsby";

import tutorial from "../data/tutorial";
import {ServerLinks} from "./servers";
import {ApiLink} from "./link";

const NavLink = ({to, children}) => (
    <Link className="nav-link text-secondary" to={to} activeClassName="active">{children}</Link>);

const GettingStartedSidebar = () => (
    <nav className="nav nav-steps flex-column">
        {tutorial.map(({to, name}) => (
            <NavLink key={to} to={to}>{name}</NavLink>
        ))}
    </nav>
);

const ApiDocSidebar = () => (
    <>
        <h6 className="text-uppercase"><Link to="/ApiDoc/">Build</Link></h6>
        <div>
            <Link className="small text-secondary" to="/ApiDoc/build-app/">Compiling & Linking Apps</Link>
        </div>
        <div className="mb-3">
            <Link className="small text-secondary" to="/ApiDoc/build-lib/">Building SQLAPI++</Link>
        </div>

        <h6 className="text-uppercase"><ApiLink annotated={true}>Class Library</ApiLink></h6>
        <div className="mb-3 d-flex flex-column">
            <ApiLink className="small text-secondary">SQLAPI.h</ApiLink>
            <ApiLink className="small text-secondary mt-2">SAPI</ApiLink>
            <ApiLink className="small text-secondary">SAConnection</ApiLink>
            <ApiLink className="small text-secondary">SACommand</ApiLink>
            <ApiLink className="small text-secondary">SAException</ApiLink>
            <ApiLink className="small text-secondary">SAField</ApiLink>
            <ApiLink className="small text-secondary">SAParam</ApiLink>
            <ApiLink className="small text-secondary mt-2">SAString</ApiLink>
            <ApiLink className="small text-secondary">SADateTime</ApiLink>
            <ApiLink className="small text-secondary">SANumeric</ApiLink>
            <ApiLink className="small text-secondary mt-2">SAGlobals</ApiLink>
            <ApiLink className="small text-secondary mt-2" api="SQLAPI_C.cpp">C binding</ApiLink>
        </div>

        <h6 className="text-uppercase"><Link to="/ApiDoc/servers/">Server Specific Guides</Link></h6>
        <div className="d-flex flex-column">
            <ServerLinks linkClass="small text-secondary"/>
        </div>
    </>
);

export {
    GettingStartedSidebar,
    ApiDocSidebar
};
